<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'academic-year-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter année universitaire</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="academicYears"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'principal_deliberation'">
          <b-badge :variant="delibVariant(props.row.principal_deliberation)">
            {{ props.row.principal_deliberation }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'control_deliberation'">
          <b-badge :variant="delibVariant(props.row.control_deliberation)">
            {{ props.row.control_deliberation }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'state'">
          <b-badge :variant="stateVariant(props.row.state)">
            {{ props.row.state }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="KeyIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="updateCurrentAcademicYear(props.row.id)"
            />
            <b-tooltip
              title="Devenir l'année courante"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip, BBadge,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    BBadge,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      columns: [
        {
          label: 'Année',
          field: 'academic_year',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Année',
          },
        },

        {
          label: 'Etat',
          field: 'state',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Etat',
          },
        },
        {
          label: 'Date fin échéancier',
          field: 'start_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Délib Principale de Tous les Niveaux',
          field: 'principal_deliberation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Délibération',
          },
        },
        {
          label: 'Délib Rattrapage de Tous les Niveaux',
          field: 'control_deliberation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Délibération',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      academicYears: [],
      searchTerm: '',

    }
  },
  computed: {
    delibVariant() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }
      return status => statusColor[status]
    },
    stateVariant() {
      const statusColor = {
        Ouverte: 'light-success',
        Clôturée: 'light-danger',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.getAcademicYears()
    this.user = storeAuth.state.user
  },
  methods: {
    async updateCurrentAcademicYear(id) {
      try {
        const response = await axios
          .put(
            `/api/academic-years/update-current-year/${id}/`,
          )
        storeAuth.commit('setCurrentAcademicYear', response.data)
        this.getAcademicYears()
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Année universitaire Modifié avec succés')
        }, 1000)
      } catch (err) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', err.toString())
        }, 1000)
      }
    },
    async getAcademicYears() {
      this.load = 'true'
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
      this.load = 'false'
    },

    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
